import React from 'react';
import { useApiClient } from '../../api';
import * as UI from '@openstax/ui-components';
import {
  AssignmentReadResponse
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments';
import { OptionsConfig } from '@project/lambdas/build/src/services/activityConfigResolver';
import { FlattenedActivityStep } from '../utils/activity-step-utils';
import { FetchState, fetchLoading, fetchSuccess, stateHasData } from '@openstax/ts-utils/fetch';

type Option = {name: string; value: string};

export type Options = {[key: string]: string | boolean};

export type AssignmentOptions = {
  [key: string]: {
    options?: Options;
    activities?: {[key: string]: Options};
  };
};

export const useAssignmentOptions = (
  assignment: AssignmentReadResponse | undefined,
  activities: FlattenedActivityStep[]
) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();

  const [assignmentOptions, setAssignmentOptions] = React.useState<AssignmentOptions>(assignment?.options ?? {});
  const [integrationOptions, setIntegrationOptions] = React.useState<FetchState<OptionsConfig, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0GetActivityOptions({})
      .then((response: any) => response.acceptStatus(200).load())
      .then((response: any) => setIntegrationOptions(fetchSuccess(response)))
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  const updateOption = React.useCallback((
    {name, value} : Option,
    integration: string,
    activityType: string | undefined,
    previous: AssignmentOptions
  ): AssignmentOptions => {
    const integrationOps = previous[integration] || {};
    const {options, activities} = integrationOps;
    return {...previous,
      [integration]: {
        ...integrationOps,
        ...(activityType
          ? { activities: {...activities,
            [activityType]: {...(activities ? activities[activityType] : {}), [name]: value}}
          }
          : { options: {...options, [name]: value} })
      }
    };
  }, []);

  // check which option groups to apply based on selected activities
  const optionGroups: OptionsConfig = React.useMemo(() => {
    if (!stateHasData(integrationOptions)) return {};

    return Object.fromEntries(Object.entries(integrationOptions.data).map(([i, config]) => {

      const assignmentUsesIntegration = activities.some(a => a.activity.integration === i);

      // both a service and its activity types can have options groups so we need to check both
      const activityTypeOptionsInAssignment = config.activities
        ? Object.fromEntries(Object.entries(config.activities)
          .filter(([typeName]) => activities.some(a => a.activity.type === typeName)))
        : [];
      const integrationHasOptions = Object.keys(config.optionGroups);

      return assignmentUsesIntegration && integrationHasOptions
        ? [i, {...config, activities: activityTypeOptionsInAssignment}]
        : [];
    }).filter(s => s.length));
  }, [integrationOptions, activities]);

  React.useEffect(() => {
    setAssignmentOptions(previous => {

      let currentAssignmentOptions = previous;

      for (const [integration, config] of Object.entries(optionGroups)) {
        const currentOptions = currentAssignmentOptions[integration] || {};

        for (const group of config.optionGroups) {
          for (const option of group.options) {

            // 'name' on option is deprecated, it should be on the optionInputs
            if (option.name) {
              if (option.defaultValue && currentOptions.options?.[option.name] === undefined) {
                const {name, defaultValue} = option;
                currentAssignmentOptions = updateOption(
                  {name, value: defaultValue}, integration, undefined, currentAssignmentOptions
                );
              }
            } else if (option.optionInputs) {
              for (const input of option.optionInputs) {
                if (input.name && input.defaultValue && currentOptions.options?.[input.name] === undefined) {
                  const {name, defaultValue} = input;
                  currentAssignmentOptions = updateOption(
                    {name, value: defaultValue}, integration, undefined, currentAssignmentOptions
                  );
                }
              }
            }
          }
        }
        for (const [activityType, groups] of Object.entries(config.activities)) {
          for (const group of groups) {
            for (const option of group.options) {

              // 'name' on option is deprecated, it should be on the optionInputs
              if (option.name) {
                if (option.defaultValue && currentOptions.activities?.[activityType]?.[option.name] === undefined) {
                  const {name, defaultValue} = option;
                  currentAssignmentOptions = updateOption(
                    {name, value: defaultValue}, integration, activityType, currentAssignmentOptions
                  );
                }
              } else if (option.optionInputs) {
                for (const input of option.optionInputs) {
                  if (
                    input.name && input.defaultValue
                    && currentOptions.activities?.[activityType]?.[input.name] === undefined
                  ) {
                    const {name, defaultValue} = input;
                    currentAssignmentOptions = updateOption(
                      {name, value: defaultValue}, integration, undefined, currentAssignmentOptions
                    );
                  }
                }
              }
            }
          }
        }
      }

      return currentAssignmentOptions;
    });
  }, [optionGroups, activities, updateOption]);

  const handleOptionChange = React.useCallback((value, {integration, activityType, option}) => {
    setAssignmentOptions((previous) => {
      return updateOption({name: option, value}, integration, activityType, previous);
    });
  }, [updateOption]);

  // check if a given option is disabled based on option form state
  const optionIsDisabled = React.useCallback((
    integration: string,
    disabler: Option,
    activityType?: string
  ) => {
    const {name, value} = disabler;
    const {activities, options} = assignmentOptions[integration];
    return value === (activityType && activities
      ? activities[activityType][name]
      : options?.[name]);
  }, [assignmentOptions]);

  return {
    assignmentOptions,
    optionGroups,
    handleOptionChange,
    optionIsDisabled
  };
};
