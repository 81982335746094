import React from 'react';
import styled, { css } from 'styled-components';
import { TooltipTrigger } from 'react-aria-components';
import { useOrn, useOrns } from "../utils/orn-utils";
import { AnyOrnResource } from "@openstax/orn-locator";
import { stateHasData, FetchStateType, fetchError } from "@openstax/ts-utils/fetch";
import { useApiClient } from "../../api";
import { FetchState, fetchIdle, fetchLoading, fetchSuccess } from "@openstax/ts-utils/fetch";
import {
  SearchActivitiesResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/select-activity";
import {
  Topics, License, IntegrationSearchResultActivityGroup,
  IntegrationSearchResult, applyTopicSortSync, IntegrationSearchResultItem,
} from "@project/lambdas/build/src/services/activityConfigResolver";
import {
  IntegrationActivity
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/types";
import * as UI from '@openstax/ui-components';
import { useServices } from "../../core/context/services";
import { Html } from "./Html";
import HeadingWithLink from './HeadingWithLink';
import * as Sentry from '@sentry/react';
import { ActivityUpdateHandler } from "./SelectScopeContent";
import newTab from '../../assets/icons/new-tab.svg';
import {
  FlattenedActivityStep,
  matchStep,
  stepIsRemoved,
  addPersistableFields,
  filterAddon,
  matchTopicsFor,
  flattenSearchResult
} from "../utils/activity-step-utils";
import { shouldHideLicense } from '../utils/select-activity';
import { LinkButton } from "../../components/LinkButton";
import { useOpenPreviewActivities } from "../screens/ActivitySetPreview";
import { WithRequired } from "@openstax/ts-utils/types";

export const PillContainer = styled.span`
  margin: 0;

  > span + span {
    margin-left: 0.3rem;
  }
`;
export const Pill = styled.span`
  white-space: nowrap;
  background-color: ${UI.colors.palette.neutralMedium};
  border-radius: 1rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.2rem 0.8rem;
`;

const CheckBoxItemContainer = styled.div<{parent?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  color: ${UI.colors.palette.neutralDarker};

  > div + div {
    margin-left: ${props => props.parent ? '3.6rem' : '3rem'};
    color: ${UI.colors.palette.neutralThin};
  }

  details {
    margin: 1rem 0 0 0;
    padding: 0.6rem;

    ol, li {
      // list markup is added for screen-readers and shouldn't change the visual style
      all: unset;
    }

    &[open] {
      padding: 0.5rem;
      max-height: 10rem;
      overflow: auto;
      border: 0.1rem solid #ccc;
    }
  }
`;

const disabledCss = `
  cursor: default;
  img {
    opacity: 0.4;
  }
`;

const LaunchLink = styled.a`
  margin-left: 0.6rem;
  ${({ disabled }: { disabled?: boolean }) => disabled && disabledCss}
`;

const LaunchButton = styled(LinkButton)`
  margin-left: 0.6rem;
  ${({ disabled }: { disabled?: boolean }) => disabled && disabledCss}
`;

const BottomArea = styled.div`
  flex: 1;
`;

const ActivityDescription = styled(Html)`
  margin: 0.4rem 0 0;
`;

const ActivityTitle = styled.span`
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
`;

const TopicTags = ({topics}: {topics: Topics}) => {
  const orns = React.useMemo(() => Object.keys(topics), [topics]);
  const resources = useOrns(orns);

  if (!stateHasData(resources)) {
    return null;
  }

  return <PillContainer>
    {resources.data.map(resource => {
      if (resource.type === 'book:page' && resource.titleParts.numberText) {
        return <Pill key={resource.orn}>{resource.titleParts.numberText}</Pill>;
      }
      return null;
    })}
  </PillContainer>;
};

const LaunchElement = (props: {
  disabled?: boolean;
  tooltip?: string;
  launchUrl?: string;
  onLaunch?: React.MouseEventHandler<HTMLElement>;
  ariaLabel: string;
}) => {
  const authProvider = useServices().authProvider;

  const newTabElement = props.tooltip
    ? <UI.TooltipGroup placement='top' icon={newTab}>{props.tooltip}</UI.TooltipGroup>
    : <img src={newTab} alt='' />;

  return props.launchUrl
    ? <LaunchLink
      onClick={props.disabled ? (e) => e.preventDefault() : props.onLaunch}
      disabled={props.disabled}
      target="_blank"
      rel="noreferrer"
      aria-label={props.ariaLabel}
      aria-disabled={props.disabled}
      href={authProvider.getAuthorizedLinkUrl(props.launchUrl)}
    >
      {newTabElement}
    </LaunchLink>
    : props.onLaunch
      ? <LaunchButton
        onClick={props.onLaunch}
        aria-label={props.ariaLabel}
        disabled={props.disabled}
      >
        {newTabElement}
      </LaunchButton>
      : null;
};

const CheckBoxItem = (props: React.PropsWithChildren<{
  selected: boolean;
  disabled?: boolean;
  launchDisabled?: boolean;
  launchDisabledMessage?: string;
  label: string;
  topics?: Topics;
  parent?: boolean;
  onChange: (checked: boolean) => void;
  launchUrl?: string;
  onLaunch?: React.MouseEventHandler<HTMLElement>;
}>) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.checked);
  };

  return <CheckBoxItemContainer parent={props.parent}>
    <div>
      <UI.Checkbox
        disabled={props.disabled}
        checked={props.selected}
        onChange={onChange}
        bold={props.parent}
        size={props.parent ? 2 : 1.4}
        aria-label={props.label}
        name={props.label}
        value='Include'
        data-analytics-input
      >
        <ActivityTitle>
          <Html block={false}>{props.label}</Html>
          <LaunchElement
            disabled={props.launchDisabled}
            tooltip={props.launchDisabled ? 'No content available for current selection' : undefined}
            launchUrl={props.launchUrl}
            onLaunch={props.onLaunch}
            ariaLabel={`Launch ${props.label}`}
          />
        </ActivityTitle>
        {props.topics ? <TopicTags topics={props.topics} /> : null}
      </UI.Checkbox>
    </div>
    <div data-analytics-parent={'Select: ' + props.label}>{props.children}</div>
  </CheckBoxItemContainer>;
};

const formatSubScopeTitle = (titleParts: {[key: string]: string}) => titleParts.numberText && titleParts.shortTitle
    ? `${titleParts.numberText}: ${titleParts.shortTitle}`
    : (titleParts.title || '');

const Header = (props: {
  data: AnyOrnResource;
}) => {
  const defaultPageState = useOrn(
    "default_page" in props.data ? props.data.default_page?.orn : undefined,
  );

  const authProvider = useServices().authProvider;
  const subScopeTitle = 'titleParts' in props.data ? formatSubScopeTitle(props.data.titleParts as any) : '';
  const subScopeUrl = stateHasData(defaultPageState) && 'title' in props.data && 'urls' in defaultPageState.data
    ? authProvider.getAuthorizedLinkUrl(defaultPageState.data.urls.main)
    : '';

  return subScopeTitle ? (
    <HeadingWithLink
      url={subScopeUrl}
      title={subScopeTitle}
      ariaLabel={`Launch ${subScopeTitle}`}
    />
  ) : null;

};

const useSearchActivities = (scope: AnyOrnResource, topics: string[]) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<SearchActivitiesResponse, string>>(fetchIdle());
  const topicsWithScope = React.useMemo(() => [...topics, scope.orn], [topics, scope]);
  const inputRef = React.useRef<typeof topicsWithScope>();
  inputRef.current = topicsWithScope;

  React.useEffect(() => {
    if (topicsWithScope.length < 1) {
      topicsWithScope === inputRef.current &&
        setState(fetchSuccess({items: []}));
      return;
    }

    setState(previous => fetchLoading(previous));
    apiClient.apiV0SearchActivities({
      query: {topics: topicsWithScope.join(',')},
    })
      .then(response => response.acceptStatus(200).load())
      .then(response => {
        if (topicsWithScope !== inputRef.current) {
          return;
        }

        response.items = applyTopicSortSync(scope, response.items);

        setState(fetchSuccess(response));
      })
      .catch((e) => {
        // Capture but don't interrupt user if the error was from a previous request
        if (topicsWithScope === inputRef.current) {
          setAppError(e);
          setState(fetchError('error searching activities'));
        } else {
          Sentry.captureException(e);
        }
      })
    ;
  }, [apiClient, topicsWithScope, scope, setAppError]);

  return state;
};

const findByHash = <A extends {resultHash?: string}>(activities: A[], hash: string | undefined) =>
  hash === undefined ? undefined : activities.find(a => a.resultHash === hash);

const ActivityWithSubItems = (props: {
  result: IntegrationSearchResult<IntegrationActivity> & IntegrationSearchResultActivityGroup<IntegrationActivity>;
  selectedContent: string[];
  selectedActivities: FlattenedActivityStep[];
  onChange: ActivityUpdateHandler;
}) => {
  const previewActivities = useOpenPreviewActivities();
  const itemState = React.useMemo(() => props.result.items.map(resultItem => {
    const existingItem = findByHash(props.selectedActivities, resultItem.resultHash);
    const selectedData = existingItem || addPersistableFields(resultItem);
    const filterAddonForItem = filterAddon(matchTopicsFor([resultItem]));

    return {
      resultItem,
      existingItem,
      selectedData,
      isSelected: !stepIsRemoved(existingItem),
      addonResults: (props.result.addons || [])
        // find addons that match this item and then flatten the results
        .flatMap(addon => filterAddonForItem(addon).flatMap(flattenSearchResult))
        .map(addonResult => {
          const existingAddon = findByHash(props.selectedActivities, addonResult.resultHash);
          const selectedAddonData = existingAddon || addPersistableFields(addonResult, selectedData);
          return {existingAddon, selectedAddonData};
        }),
    };
  }), [props.result, props.selectedActivities]);

  const addonState = React.useMemo(() => (('addons' in props.result && props.result.addons) || []).map(addon => {
    const flatAddonResult = flattenSearchResult(addon);

    const flatAddons = itemState.map(item =>
      item.addonResults.filter(itemAddon => findByHash(flatAddonResult, itemAddon.selectedAddonData.resultHash))
    ).flat();

    // this is all the prepared addon data from the item list that match this addon
    const availableAddons = itemState.map(item => stepIsRemoved(item.existingItem)
      ? []
      : item.addonResults.filter(itemAddon => findByHash(flatAddonResult, itemAddon.selectedAddonData.resultHash))
    ).flat();

    // addons are selected if any of their items are selected. missing items
    // can be selected by un-checking and rechecking
    const isSelected = availableAddons.length === 0 ||
      availableAddons.some(available => !stepIsRemoved(available.existingAddon));

    return {
      addonResult: addon,
      flatAddons,
      availableAddons,
      isDisabled: availableAddons.length === 0,
      isSelected
    };
  }), [props.result, itemState]);

  const selected = itemState.some(item => item.isSelected);

  const selectedItems = itemState.filter(item => item.isSelected).map(item => item.resultItem);
  const matchTopicsForSelected = matchTopicsFor(selectedItems);

  const activityHasPreview = (
    check: IntegrationSearchResultItem<IntegrationActivity>
  ): check is WithRequired<IntegrationSearchResultItem<IntegrationActivity>, 'preview'> =>
    !!check.preview;

  const activityHasPreviewAndIsSelected = (
    check: IntegrationSearchResultItem<IntegrationActivity>
  ): check is WithRequired<IntegrationSearchResultItem<IntegrationActivity>, 'preview'> =>
    matchTopicsForSelected(check) && activityHasPreview(check);

  const filterPreviewForSelected = filterAddon(activityHasPreviewAndIsSelected);
  const filterPreview = filterAddon(activityHasPreview);

  return <CheckBoxItem
    selected={selected}
    label={props.result.title}
    parent={true}
    onChange={(checked) => {
      const allActivities = itemState.map(item => [
        item.selectedData,
        ...item.addonResults.map(({selectedAddonData}) => selectedAddonData)
      ]).flat();
      props.onChange(checked
        ? {selected: allActivities}
        : {unselected: allActivities}
      );
    }}
  >
    <LicenseNotice license={props.result.license} />
    <ActivityDescription block>{props.result.description}</ActivityDescription>

    {addonState.map(({addonResult, availableAddons, isSelected, isDisabled}, index) => {
      const toPreview = selectedItems.length > 0
        ? filterPreviewForSelected(addonResult)
        : filterPreview(addonResult);

      return <CheckBoxItem
        key={index}
        disabled={isDisabled}
        launchDisabled={!toPreview.length}
        selected={!isDisabled && isSelected}
        onLaunch={() => previewActivities(toPreview)}
        onChange={checked => {
          const allAvailableAddonData = availableAddons.map(({selectedAddonData}) => selectedAddonData);
          props.onChange(checked
            ? {selected: allAvailableAddonData}
            : {unselected: allAvailableAddonData}
          );
        }}
        label={addonResult.title}
      >
        <LicenseNotice license={addonResult.license} />
        <Html block>{addonResult.description}</Html>
      </CheckBoxItem>;
    })}

    <details open>
      <summary>{props.result.itemsLabel ?? 'Choose Items'}</summary>
      <ol>
        {itemState.map((item) =>
          <li key={item.selectedData.title}>
            <CheckBoxItem
              selected={item.isSelected}
              onChange={checked => {
                const activityData = [
                  item.selectedData,
                  // including addons as long as long as the addon is checked
                  ...item.addonResults
                    .filter(itemAddon => {
                      const matchThisItem = matchStep(itemAddon.selectedAddonData);
                      return addonState.find(state => state.isSelected && state.flatAddons.find(
                        stateAddon => matchThisItem(stateAddon.selectedAddonData)
                      ));
                    })
                    .map(({ selectedAddonData }) => selectedAddonData)
                ];
                props.onChange(checked
                  ? { selected: activityData }
                  : { unselected: activityData }
                );
              }}
              label={item.selectedData.title}
            ></CheckBoxItem>
          </li>
        )}
      </ol>
    </details>
  </CheckBoxItem>;
};

const LicenseNotice = styled((props: {license?: License | undefined; className?: string}) => {
  if (!props.license || shouldHideLicense(props.license.name)) {
    return null;
  }
  const year = new Date().getFullYear();
  const licenseText = `© ${props.license.holder} ${year}. ${
    props.license.url
      ? <a href={props.license.url} target="_blank" rel="noreferrer">{props.license.name}</a>
      : props.license.name
  }.`;

  return props.license.notice
    ? <TooltipTrigger delay={0}>
        <UI.StyledTrigger>{licenseText}</UI.StyledTrigger>
        <UI.Tooltip placement='bottom'><Html>{props.license.notice}</Html></UI.Tooltip>
      </TooltipTrigger>
    : <div className={props.className}>{licenseText}</div>;
})`
  &, & > a {
    ${({ license }) => license && (license.url || license.notice) ? css`
      color: ${UI.colors.palette.mediumBlue};
    ` : css`
      color: ${UI.colors.palette.neutralThin};
      font-weight: bold;
    `}
  }
  margin: 0.25rem 0 0.5rem 0;
`;

const ActivitySelect = (props: {
  scope: AnyOrnResource;
  selectedContent: string[];
  selectedActivities: FlattenedActivityStep[];
  onChange: ActivityUpdateHandler;
}) => {
  const state = useSearchActivities(props.scope, props.selectedContent);
  return <BottomArea>
    <Header data={props.scope} />
    <div data-analytics-parent="Select: All activities">
      {state.type === FetchStateType.SUCCESS
        ? state.data.items.map((result, index) => {
          if ('items' in result) {
            return <ActivityWithSubItems {...props} result={result} key={index} />;
          } else {
            return <CheckBoxItem
              key={index}
              parent={true} // TODO - rename this prop. 7 months later... to what? "topLevel"? child=false?
              selected={!stepIsRemoved(findByHash(props.selectedActivities, result.resultHash))}
              onChange={checked => props.onChange(previous => {
                const activity = findByHash(previous, result.resultHash) || addPersistableFields(result);
                return checked ? {selected: [activity]} : {unselected: [activity]};
              })}
              topics={result.activity.topics}
              label={result.activity.title}
              launchUrl={result.launch?.url ?? result.preview?.url}
            >
              <LicenseNotice license={result.activity.license} />
              <ActivityDescription block>{result.activity.description}</ActivityDescription>
            </CheckBoxItem>;
          }
        })
        : <div
          style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        ><UI.Loader delay={500} /></div>
      }
    </div>
    <ActivitiesFooter />
  </BottomArea>;
};

const ActivitiesFooter = styled((props) =>
  <footer className={props.className}>
    Content in Assignable - including OpenStax books - is offered to you by the
    author of those works under the CC BY 4.0 license unless otherwise noted above.
  </footer>
)`
  color: ${UI.colors.palette.neutralThin};
  border-top: 0.1rem solid ${UI.colors.palette.pale};
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  line-height: 2rem;
`;

export const ContentActivitySelect = (props: {
  scope: AnyOrnResource;
  selectedContent: string[];
  selectedActivities: FlattenedActivityStep[];
  onChange: ActivityUpdateHandler;
}) => <ActivitySelect {...props} />;
