import React from 'react';
import { colors, NavBar } from '@openstax/ui-components';
import styled from 'styled-components';
import { TextResizer } from '../../components/TextResizer';
import { TextResizerValue } from "../../constants";
import useHelpMenu from '../../components/HelpMenu';
import KebabMenu from '../../components/KebabMenu';
import type {
  StatefulAssignmentResponse
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student';

const StyledNavBar = styled(NavBar)`
  h1 {
    max-width: 33.6rem;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${colors.palette.neutralDarker};

    @media screen and (max-width: 41rem) {
      max-width: 21.6rem;
    }
  }
`;
const ButtonGroup = styled.span`
  display: flex;
`;

export const Activity = ({ title, src, onChangeTextSize, defaultTextSize, isTextResizable, assignment }: {
  title: string;
  src: HTMLIFrameElement['src'];
  defaultTextSize: number;
  onChangeTextSize: (value: number) => void;
  isTextResizable?: boolean;
  assignment: StatefulAssignmentResponse;
}) => {
  const [textSize, setTextSize] = React.useState<number>(defaultTextSize);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const iframeOrigin = React.useMemo(() => new URL(src).origin, [src]);
  const [HelpMenu, ContactFormIframe] = useHelpMenu();

  return <>
    <StyledNavBar>
      <h1>{title}</h1>
      <ButtonGroup>
        {isTextResizable ? <TextResizer textSize={textSize as TextResizerValue} setTextSize={(value) => {
          iframeRef.current?.contentWindow?.postMessage({type: 'TextSizeUpdate', value}, iframeOrigin);
          onChangeTextSize(value);
          setTextSize(value);
        }} /> : null}
        <HelpMenu id={assignment.id} />
        <KebabMenu scope={assignment.scope} />
      </ButtonGroup>
    </StyledNavBar>
    <ContactFormIframe />
    <iframe
      ref={iframeRef}
      style={{ flex: 1 }}
      title={title}
      allowFullScreen
      allow="autoplay"
      src={src} />
  </>;
};
