/* eslint-disable max-len */
export const warnings = {
  create: 'You are creating a new assignment. Learn about how to edit assignments <a href="https://help.openstax.org/s/article/OpenStax-Assignable-beta-assignment-setup#edit_content_in_existing_assignment" rel="noopener noreferrer" target="_blank">here</a>.',
  copy: 'Copying assignments and courses may have unexpected results. Learn about copying <a href="https://help.openstax.org/s/article/How-do-I-copy-a-course-and-assignments-in-OpenStax-Assignable-beta" rel="noopener noreferrer" target="_blank">here</a>.',
  editOriginalLegacy: 'You are currently editing an existing assignment. If this assignment has been copied, your changes will affect those copies, including those in copied courses containing the assignment.',
  editForkedCopy: 'This assignment was copied from another source. Copying it may have unexpected results. Learn about copying <a href="https://help.openstax.org/s/article/How-do-I-copy-a-course-and-assignments-in-OpenStax-Assignable-beta" rel="noopener noreferrer" target="_blank">here</a>.',
  editLegacyCopy: 'This assignment was copied from another source and cannot be edited. To edit an assignment, please create a new one.',
  editOriginal: 'You are currently editing an existing assignment. If this assignment has been copied, your changes will affect other copied assignments, including those in copied courses containing the assignment.',
  existingAttempts: 'Editing assignments after students have started work will not change existing scores in the gradebook and may confuse students.'
};
