import styled, { css } from 'styled-components';
import * as UI from '@openstax/ui-components';
import { narrowMediaQuery } from '../../../utils/styleUtils';

export const LastSyncContainer = styled.div`
  font-size: 1.2rem;
  margin: 0 1rem 1rem;
  text-align: center;
  width: 100%;
`;

// TODO: this can be removed if the style is changed in ui-components
export const StyledTooltipGroup = styled(UI.TooltipGroup)`
  min-width: unset;
`;


export const SettingsGroup = styled.div<{ padding?: string; variant?: string }>`
  border: solid 1px ${UI.colors.palette.pale};
  padding: 1.6rem 4.6rem;
  background: ${props => props.variant === 'light' ? UI.colors.palette.white : UI.colors.palette.neutralLighter};

  & + :not(&) {
     margin-bottom: 2.4rem;
  }

  & + & {
    border-top: 0;
  }

  ${narrowMediaQuery`
    padding: 1.6rem 3.6rem;
  `}
  h4 {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }

  form {
    > *:not(:first-child) {
      margin-top: 1rem;
    }

    > span {
      font-weight: 700;
      display: block;

      &:not(:first-child) {
        margin-top: 0.8rem;
      }
    }

    label span {
      font-weight: normal;
    }

    input {
      margin-left: 0;
    }
  }
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const ButtonRow = styled.div<{ justifySpaceBetween: boolean }>`
  display: flex;
  align-items: center;
  min-width: 24rem;
  gap: 1.6rem;
  width: 100%;

  ${props => props.justifySpaceBetween && `
    justify-content: space-between;
  `}

  @media (max-width: 15em) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-width: unset;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  label:not(:last-child) {
    margin-bottom: 0;
  }

  ${narrowMediaQuery(css`
    ${LastSyncContainer} {
      text-align: left;
      margin-top: 0.4rem;
    }
  `)}
`;

export const ResponsiveRow = styled(Row)`
  ${narrowMediaQuery(css`
    flex-direction: column;
  `)}
`;

export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;

  > *:not(:first-child) {
    margin-top: 1rem;
  }
`;
