import React from 'react';
import isEqual from 'lodash/fp/isEqual';
import styled, { css } from 'styled-components';
import {partitionSequence} from '@openstax/ts-utils/misc/partitionSequence';
import * as UI from '@openstax/ui-components';
import { SortableItem } from './Sortable';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToFirstScrollableAncestor
} from '@dnd-kit/modifiers';
import { FlattenedActivityStep } from "../utils/activity-step-utils";
import { AssignmentSettings, QuestionSettings } from './Settings';
import { SettingsGroup, ResponsiveRow, LastSyncContainer } from './Settings/styles';
import { useAssignmentOptions } from '../hooks/options';
import { SyncExportScores } from "./SyncExportScores";
import { AssignmentManagementStates } from "../hooks/grades";
import { narrowMediaQuery } from '../../utils/styleUtils';
import { SyncPartialScores } from './SyncPartialScores';

const PreviewContainer = styled.div`
  color: ${UI.colors.palette.neutralDarker};
  padding: 0 1.6rem;
  max-width: 60.4rem;
  margin: 0 auto;

  h3 {
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
  }

  ${narrowMediaQuery(css`
    padding: 0;
  `)}
`;

const groupSteps = (items: FlattenedActivityStep[]) => {
  return partitionSequence(
    (item: FlattenedActivityStep) => {
      return {value: item.activity.attachedTo ?? item.activity.stepId};
    },
    items
  );
};

type StepPartition = ReturnType<typeof groupSteps>[number];

const GradeSyncSettingsGroup = styled(SettingsGroup)`
  ${LastSyncContainer} {
    line-height: 2.5rem;
    margin: 0;
    font-size: inherit;
  }
`;

export const ContentPreview = (props: {
  /*
   * this is trying to follow the "uncontrolled input" prop naming
   * convention, defaultFlatActivities is only ever referenced on mount,
   * changing it won't affect the display of ContentPreview. the assumption
   * is that all changes to the list are coming from this display while its mounted.
   */
  defaultFlatActivities: FlattenedActivityStep[];
  /*
   * triggered if the order is customized, it affects how new activities are
   * added to the assignment if the user goes back to the picker screen.
   */
  setHasCustomOrder: (hasCustomOrder: boolean) => void;
  /*
   * called when items are sorted or removed.
   */
  setFlatActivities: (steps: FlattenedActivityStep[]) => void;

  editingDisabled?: boolean;

  assignmentManagementStates?: AssignmentManagementStates;
  assignmentOptions: ReturnType<typeof useAssignmentOptions>;
}) => {
  const [stepGroups, setStepGroups] = React.useState(groupSteps(props.defaultFlatActivities));

  // dnd sortable
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (over && active.id !== over.id) {
      const reorderedGroups = arrayMove(
        stepGroups,
        stepGroups.findIndex(item => item[0] === active.id),
        stepGroups.findIndex(item => item[0] === over.id)
      );

      // check latest order against initial order, ignoring steps that have been removed
      const isReordered = !isEqual(reorderedGroups, stepGroups);
      props.setHasCustomOrder(isReordered);

      setStepGroups(reorderedGroups);

      if (isReordered) {
        const reorderedActivities = reorderedGroups.map(([,steps]) => steps).flat();
        props.setFlatActivities(reorderedActivities);
      }
    }
  };

  const handleDelete = (items: FlattenedActivityStep[]) => {
    const {newGroups, flatSteps} = stepGroups.reduce((result, [groupId, steps]) => {
      const newSteps = steps.filter(step => !items.includes(step));
      if (newSteps.length > 0) {
        result.newGroups.push([groupId, newSteps]);
        result.flatSteps.push(...newSteps);
      }
      return result;
    }, {newGroups: [] as StepPartition[], flatSteps: [] as FlattenedActivityStep[]});

    setStepGroups(newGroups);
    props.setFlatActivities(flatSteps);
  };

  const handlePointsChange = (groupId: string, stepId: string, maxPoints: number) => {
    const updatedGroups = stepGroups.map(([id, steps]) => id === groupId
      ? [id, steps.map(s => s.activity.stepId === stepId
        ? {...s, maxPoints, activity: {...s.activity, maxPoints}}
        : s)]
      : [id, steps]) as [string, FlattenedActivityStep[]][];
    setStepGroups(updatedGroups);
    props.setFlatActivities(updatedGroups.map(([,steps]) => steps).flat());
  };

  const sumTotalPoints = React.useMemo(() => stepGroups.map(([,steps]) => steps).flat()
    .reduce((result, step) => result + step.activity.maxPoints, 0), [stepGroups]);

  return <>
      <PreviewContainer>
        {props.assignmentManagementStates?.enabled
          ? <>
            <h3>Grade Sync</h3>
            <GradeSyncSettingsGroup padding='0.6rem'>
              <ResponsiveRow>
                <SyncExportScores assignmentManagementStates={props.assignmentManagementStates} />
              </ResponsiveRow>
            </GradeSyncSettingsGroup>
            <GradeSyncSettingsGroup padding='0.6rem' variant="light">
              <ResponsiveRow>
                <SyncPartialScores
                  assignmentOptions={props.assignmentOptions.assignmentOptions}
                  handleChangeOptions={props.assignmentOptions.handleOptionChange}
                />
              </ResponsiveRow>
            </GradeSyncSettingsGroup>
          </>
          : null
        }
        <AssignmentSettings points={sumTotalPoints} />
        <QuestionSettings editingDisabled={props.editingDisabled} assignmentOptions={props.assignmentOptions} />
        <DndContext
          sensors={sensors}
          modifiers={[restrictToFirstScrollableAncestor]}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={stepGroups.map(([groupId]) => groupId)}
            strategy={verticalListSortingStrategy}
          >
            {stepGroups.map(([groupId, steps]) =>
              <SortableItem
                id={groupId}
                key={groupId}
                steps={steps}
                handleDelete={handleDelete}
                handlePointsChange={handlePointsChange}
                editingDisabled={props.editingDisabled}
              />
            )}
          </SortableContext>
        </DndContext>
      </PreviewContainer>
  </>;
};
